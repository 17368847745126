
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles'
import Cart from 'pages/cart/ShoppingCart'
import { MdKitchen, MdOutlineChair, MdOutlineBed } from "react-icons/md"
import { RiArchiveDrawerLine } from "react-icons/ri";
// import MdOutlineChair from "react-icons/md"

import im_mahogany_sink from 'resources/images/bathroom/mahogany_sink.jpg'
import im_peach_sink from 'resources/images/bathroom/peach_sink.jpg'
import im_marble_sink from 'resources/images/bathroom/marble_sink.jpg'

import im_bd1 from 'resources/images/bedroom/bd1.jpg'
import im_bd2 from 'resources/images/bedroom/bd2.jpg'
import im_bd3 from 'resources/images/bedroom/bd3.jpg'

import im_lving1 from 'resources/images/livingroom/lving1.jpg'
import im_lving2 from 'resources/images/livingroom/lving2.jpg'
import im_lving3 from 'resources/images/livingroom/lving3.jpg'

import im_ki1 from 'resources/images/kitchen/ki_02.jpg'
import im_ki2 from 'resources/images/kitchen/ki_03.jpg'
import im_ki3 from 'resources/images/kitchen/ki_04.jpg'

export type Filter = 'livingroom' | 'bedroom' | 'kitchen' | 'dininggroom' | 'bathroom' | 'vanities'

export interface product {
    id: string;
    img: string;
    description: string;
    category: Filter;
}


export const Queries = () => {
    const theme = useTheme()

    return {
        isBelowXS: useMediaQuery(theme.breakpoints.down('xs')),
        isBelowSM: useMediaQuery(theme.breakpoints.down('sm')),
        isBelowMD: useMediaQuery(theme.breakpoints.down('md')),   
        isBelowLG: useMediaQuery(theme.breakpoints.down('lg')),  
        isBelowXL: useMediaQuery(theme.breakpoints.down('xl')),      
    }
}


export const productLst: product[] = []

productLst.push({id: "im_mahogany_sink", description: "", img: im_mahogany_sink, category: "vanities"})
productLst.push({id: "im_marble_sink", description: "", img: im_marble_sink, category: "vanities"})
productLst.push({id: "im_peach_sink", description: "", img: im_peach_sink, category: "vanities"})

productLst.push({id: "im_mahogany_sink", description: "", img: im_bd1, category: "bedroom"})
productLst.push({id: "im_marble_sink", description: "", img: im_bd2, category: "bedroom"})
productLst.push({id: "im_peach_sink", description: "", img: im_bd3, category: "bedroom"})

productLst.push({id: "im_mahogany_sink", description: "", img: im_lving1, category: "livingroom"})
productLst.push({id: "im_marble_sink", description: "", img: im_lving2, category: "livingroom"})
productLst.push({id: "im_peach_sink", description: "", img: im_lving3, category: "livingroom"})

productLst.push({id: "im_mahogany_sink", description: "", img: im_ki1, category: "kitchen"})
productLst.push({id: "im_marble_sink", description: "", img: im_ki2, category: "kitchen"})
productLst.push({id: "im_peach_sink", description: "", img: im_ki3, category: "kitchen"})


export const lorem = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et"
export const ID_HOME_HEADER = "home-header"
export const ID_HOME_BKGRND = "home-bkgrnd"
export const CL_CONTAINER = "container"
export const ID_H_IMG = "h-img"

export const HEADER_TITLE = "ENRICH YOUR SPACE"
// export const HEADER_BODY = "Explore our products to enrich your space and beautify your home with furniture of the finest quality. "
export const HEADER_BODY = "Enrich your space and beautify your home with furniture of the finest quality. "

export const SECT_2_TITLE = "Explore By Category"



export const A_CARD_TXT1: string  = "Discover fresh picks"
export const A_CARD_TXT2 = "NEW DESIGNS"
export const EXPLORE_NOW = "learn more"
export const START_SHOPPING = "start exploring"
export const STAY_CONNECTED = "Stay connected"

// export const CATEGORIES = ['vanities', 'bedroom']
export const allCategories: Filter[] = ['bathroom', 'vanities', 'bedroom', 'dininggroom', 'kitchen', 'livingroom']

export const CATEGORIES: {[Key: string] : any} = 
    {'vanities': {icon: <RiArchiveDrawerLine/>, label: 'vanities',
        imglst: [im_mahogany_sink, im_peach_sink, im_marble_sink], 
        textlst: ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
         'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
         'Lorem ipsum dolor sit amet, consetetur sadipscing elitr']}

    , 'kitchen': {icon: <MdKitchen/>,  label: 'kitchen',
        imglst: [im_mahogany_sink, im_peach_sink, im_marble_sink], 
        textlst: ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
         'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
         'Lorem ipsum dolor sit amet, consetetur sadipscing elitr']}

    ,   'bedroom': {icon: <MdOutlineBed/>, label: 'bedroom',
        imglst: [im_mahogany_sink, im_peach_sink, im_marble_sink], 
        textlst: ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr']}

    , 'livingroom': {icon: <MdOutlineChair/>, label: 'living room',
        imglst: [im_mahogany_sink, im_peach_sink, im_marble_sink], 
        textlst: ['Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
         'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
         'Lorem ipsum dolor sit amet, consetetur sadipscing elitr']}
}



export const NavItemList  = [
    {
        title: "search",
        url: "/contact"
    },
    {
        title: "cart",
        url: "",
        func: ""
    },
    {
        title: "login",
        url: "/contact"
    },
    {
        title: "Dining",
        url: "/products"
    },
    {
        title: "Bed",
        url: "/products"
    },
    {
        title: "Bath",
        url: "/products"
    },
    {
        title: "Other",
        url: "/products"
    },
    {
        title: "About",
        url: "/contact"
    },
    {
        title: "products",
        url: "#showcase"
    },
    {
        title: "featured",
        url: "#featured"
    },
    {
        title: "Contact",
        url: "#contact"
    }
]

export const ProductsNavList  = [
    {
        title: "home",
        url: "/"
    },
    {
        title: "office",
        url: ""
    },
    {
        title: "living room",
        url: ""
    },
    {
        title: "dining room",
        url: ""
    },
    {
        title: " kitchen",
        url: ""
    },
    {
        title: " bed room",
        url: ""
    }
]

const quickLinksList  = [
    {
        title: "offers",
        url: ""
    },
    {
        title: "affiliates",
        url: ""
    },
    {
        title: "about us",
        url: ""
    },
    {
        title: "customer support",
        url: ""
    },
    {
        title: "order status",
        url: ""
    },
    {
        title: "careers",
        url: ""
    },
    {
        title: "designer program",
        url: ""
    },
    {
        title: "catalog",
        url: ""
    },
    {
        title: "rug guide",
        url: ""
    },
    {
        title: "friend referral",
        url: ""
    },
    {
        title: "covid-19 response",
        url: ""
    },
]

export const Lists = {
    quickLinks: quickLinksList
}