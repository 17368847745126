import { } from '@material-ui/core/colors';
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


export const PRIMARY_THEME = responsiveFontSizes(createMuiTheme({
    typography: {
        fontFamily: [
            'Playfair',
            'Poppins',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#f5f5f5',            
          },
          secondary: {
            main: '#2c3e50',
          },
          text: {
            primary: '#ffffff',
            secondary: '#000000',
            
        },
    }

}));


export const CHECKOUT_THEME = responsiveFontSizes(createMuiTheme({
    typography: {
        fontFamily: [
            'Playfair',
            'Poppins',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#458B00',            
          },
          secondary: {
            main: '#2c3e50',
          },
          text: {
            primary: '#000000',
            secondary: '#808080',
            
        },
    }

})); 



export const SECONDARY_THEME = createMuiTheme({
    ...PRIMARY_THEME,
    typography: {
        fontFamily: [
            'Poppins',
            'Playfair',
        ].join(',')
    }

});

export const BREADCRUMBS_THEME = createMuiTheme({
    ...SECONDARY_THEME,
    palette: {
          text: {
            primary: '#222527;',        
        },
    }
    
})


export const PRIMARY_BTN = createMuiTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#2c3e50',
          },
          secondary: {
            main: '#000000',
          },
    }
});
