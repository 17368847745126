import React from 'react'

import * as R from 'resources/Utils'
import * as comps from 'pages/home/components/Components'
import ShowCase from 'pages/showcase/showcase'
import * as homeStyles from './styling/HomeStyles'
import bedroom from 'resources/images/bedroom/bedroom_image-min_crop.jpg'
import kitchen from 'resources/images/kitchen/ki_04.jpg'
import bathroom from 'resources/images/bathroom/bathroom.jpg'
import livingroom from 'resources/images/livingroom/lving3.jpg'
// import cat_im from 'resources/images/bedroom.jpg'
// import arrivals_preview from 'resources/images/new_arrivals_preview.jpg'
// import featured_im from 'resources/images/featured_products_bckgrnd.jpg'
import {AppBarPrimary} from 'Appbars/Appsbars'


import {useTheme} from '@material-ui/core/styles'

import { Box, Typography,  createMuiTheme } from '@material-ui/core'
import {PRIMARY_THEME} from 'resources/fonts/MyFonts'


const HomePage = (): JSX.Element => {

    const theme = useTheme()
    const sizeCheck = R.Queries()
    
    function getSize(){
        return `${window.screen.width}| ${window.screen.availWidth} |  ${window.screen.height} | ${window.screen.availHeight} `
    }

    

  let genUpperMid = () => {
        let categories = ['Bathroom', 'Living Room', 'Kitchen', 'Bedroom']
    
        return categories
    }

    let cardLst = genUpperMid()

    let LoadImgs = () => {
        // CALL API HERE
        let marr = [bathroom, livingroom, kitchen, bedroom]  
        

        return marr
    }

    const loadProducts = () => {
        
    }

    
    const S = homeStyles.default(createMuiTheme());

    return (
        <React.Fragment>                           
                <comps.ImageSlider images={LoadImgs()}/>
                {/* <AppBarPrimary/> */}
                              
                {sizeCheck.isBelowSM && 
                    <Box  css={S.CHIN}>
                        <comps.ImageSliderChin/>                
                    </Box>}
                
                <Box css={[S.CONTAINER, S.BODY]}>
                    <comps.ShoppingSelector images={LoadImgs()} categories={genUpperMid()}/>
                    {/* <Box bgcolor='red' width='100px' height='100px'></Box> */}
                    {/* <ShowCase/> */}
                    {/* <comps.Testimonials/>   */}
                    <comps.ContactForm/>                                  
                </Box>
                    
                {/* <comps.UpperFooter/> */}
                {/* <Box bgcolor={PRIMARY_THEME.palette.secondary.main} css={S.QuickLinks}>
                    {!sizeCheck.isBelowSM && <comps.QuickLinks/>}
                    <comps.MailSection />
                </Box> */}
                
                
                {/* <footer css={S.FOOTER}>
                    <Box css={S.SocialMedia}>
                        {!sizeCheck.isBelowSM && <comps.SocialMedia text='Follow Us' color='#bfffff'/>}
                    </Box>
                    
                    <Typography color='textPrimary' align='center' css={S.COPYRIGHT}>
                        COPYRIGHT © 2021 KINGSTON FURNITURE LTD. {!sizeCheck.isBelowSM && <br/>} ALL RIGHTS RESERVED.
                    </Typography>
                    
                </footer> */}

                <comps.Footer/>
        </React.Fragment>
    )
}

export default HomePage