

import React, { Component, FormEvent, useState, useEffect } from 'react'
import { css, Global} from '@emotion/react'
import imgSrc from 'resources/images/bedroom/bedroom_image_promo_cropped.jpg'
import Validateform from './Validateform'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import emailjs from 'emailjs-com';
import PromoDesktop from './promo_desktop';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import mgif from 'resources/images/success_one.gif';
import {ClipLoader} from 'react-spinners'


export default function PromoPage() {
    enum appState {
        submitted = 'submitted',
        filling = 'filling',
        submitting = 'submitting',
        unsubmitted = 'unsubmitted'
    }

    const [values, setvalues] = useState({name: '', email: '', message: ''})
    const [errors, setErrors] = useState<Record<string, string>>({nameErr: '', emailErr: '', msgErr: '', api: ''})
    const [state, setState] = useState<appState>(appState.unsubmitted)
    const [showFooter, setShowFooter] = useState(true)
    let hasErrors = Object.keys(errors).some(function(k) {return errors[k]})


    const CONTAINER_STYLE = css`
    width: 100%;
    height: 100%;
    position: relative;
    /* border: solid red; */
    `

     function startPage(): EmotionJSX.Element {
        
        const FOOTER_STYLE = css`
        position: absolute;
        bottom: 0;
        height: 5.1%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        font-size: 11px;
        font-size: 2.8vw;
        color: #f5f5f5;
        font: outline;
        background: rgb(68, 67, 67);
        font-family: var(--secondary-font);
    `
    return (
        <Router>
            <div css={CONTAINER_STYLE}>
                <Switch>
                    <Route exact path='/'>
                        {Promo()}
                    </Route>
                    <Route exact path='/contact'>
                        {ContactForm()}
                    </Route>
                </Switch>                            
                {showFooter && <div css={FOOTER_STYLE}>COPYRIGHT © 2021 KINGSTON FURNITURE LTD. ALL RIGHTS RESERVED.</div>}
            </div>    
        </Router>
    )
  
    }


    function Promo() {

        const IMG_STYLE = css`
            width: 100%;
            height: 100%;
            background-size: cover;
        `
        const LOGO_STYLE = css`
            position: absolute;
            top: 27.5%;
            left: 50%;
            transform: translateX(-50%);
            color: #ffe0e0;
            font-size: 36px;
            padding: 3.5px 3px ;
            border: solid 1px #ffffff;
        `

        const TITLE_STYLE = css`
            position: absolute;
            top: 37.3%;
            left: 50%;
            transform: translateX(-50%);
            font-size: 47px;
            font-size: 9vw;
            margin-top: 10px;
            white-space: nowrap;
            color: #ffe0e0;
            /* @media only screen and (max-height: 667px){
            margin-top: 10px;
        } */
        `

        const BTN_STYLE = css`
            position: absolute;
            top: 50%;
            left: 50%;
            text-decoration: none;
            transform: translateX(-50%);
            font-size: 14px;
            font-size: 3.6vw;
            padding: 7.5px 57.8px 7.6px 57.7px;
            white-space: nowrap;
            background-color: #c93736;
            color: #ffe0e0;
            font-family: var(--secondary-font);
        `
 

            return (
                <React.Fragment>       
                    <img src={imgSrc} alt='background' css={IMG_STYLE}/>
                    <div css={LOGO_STYLE}>KF</div>
                    <div css={TITLE_STYLE}>Under Construction</div>
                    <Link to='/contact' css={BTN_STYLE} onClick={()=>{setState(appState.filling)}}>      
                        CONTACT</Link>                     
                </React.Fragment>    
            )        
    }




    let ContactForm = () => {

        const TOP_DECAL_STYLE = css`
            height: 1.9%;
            width: 82.7%;
            margin: 0 auto;
            background: rgb(77, 72, 72);
        `

        const FORM_STYLE = css`
            width: 100%;
            height: 100%;
            background: white;
            position: relative;
            /* overflow: scroll; */


            @media only screen and (min-width: 1000px){
                /* border: solid 2px red; */
                position: relative;
                height: 100%;
                width: 34.4%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background: white;
                /* z-index: 2; */

                form:before {
                    content: "";
                }
            }`

        const LOGO_STYLE = css`
            width: fit-content;
            margin: 21.6px auto;
            color: black;
            font-size: 36px;
            padding: 3.5px 3px ;
            border: solid 1px black;
        `

        const TEXT_BOX_STYLE = css`
            height: 97.6px;
            `


        const TITLE_STYLE = css`
        width: fit-content;
        margin: 5.9% auto 8.8%;
        color: black;
        font-size: 9vw;
        /* font-family: 'Poppins';   */
        white-space: nowrap;
        
        `
        //
        const BTN_STYLE = css`
            height: 32.1px;
            width: 45.7%;
            display: block;
            margin: 33px auto;
            border: none;
            background-color: #c93736;
            font-weight: 100;
            font-size: 12px;
            border-radius: 3px;
            color: #bfffff;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            text-align: center;

        `

        const loader_STYLE = css`
            height: 32.1px;
            width: 45.7%;
            text-align: center;  
            margin: 33px auto;
            background-color: #c93736;   
            /* padding: auto auto; */
            padding-top: 5px;
            padding-bottom: 35px;
        `
 

        const GLOBAL_STYLE = css`
            label {
                width: fit-content;
                width: 75.3%;
                margin: 0 auto;
                display: block;
                color: rgb(0, 0, 32);
                font-family: var(--secondary-font);
                }
            //
            input, textarea {
                display: block;
                height: 30px;
                width: 75.3%;
                align-self: center;
                padding-left: 5px;
                margin: 8px auto 3.5%;
                ${hasErrors ? css`margin-bottom: 8px;`: css`margin-bottom: 0px;`};
                border: 1px solid lightgray;   
                background: #fff;       
                color: black;
                outline: none;
                font-family: var(--secondary-font); 

            } 

            input:focus, textarea:focus {
                border: 1px solid black;
                
            }
            
            p.err{
                font-size: 12px;
                color: red;
                width: 75.3%;
                margin: 0px auto;
            }
            `

        const normalForm = <>
                    <div css={TITLE_STYLE}>Under Construction</div>           
                    <label>Your Full Name:</label>
                    <input onChange={onChange} placeholder="full name" name='name' type='text'></input>
                    {errors.nameErr && <p className='err'>{errors.nameErr}</p>}
    
                    <label>Your Email Address:</label>
                    <input onChange={onChange} placeholder="email address" name='email' type='email'></input>
                    {errors.emailErr && <p className='err'>{errors.emailErr}</p>}
    
                    <label>Your Message:</label>
                    <textarea onBlur={() => {console.log("called");setShowFooter(true)}} onFocus={() => {setShowFooter(false)}} onChange={onChange} name='message' css={TEXT_BOX_STYLE}></textarea>
                    {errors.msgErr && <p className='err'>{errors.msgErr}</p>}
                    {/* {state as appState == appState.submitted && <p></p>} */}
                    {state === appState.submitting ? <div css={loader_STYLE}><ClipLoader color='#bfffff' size={28}/></div> : <button formAction='submit' css={BTN_STYLE}>SEND MESSAGE</button>}
                    
    
                    {errors.api && <span className="serverErr">{errors.api}</span>}
        </>;

        return (
                <React.Fragment>
                    <Global styles={GLOBAL_STYLE}></Global>
                    <form css={FORM_STYLE} action="submit" onSubmit={onSubmit}>
                        <div css={TOP_DECAL_STYLE}></div>
                        <div css={LOGO_STYLE}>KF</div>
                        {state !== appState.submitted ? normalForm : SuccessScreen()}                     
                    </form>    
            </React.Fragment>
            )


            function onSubmit(event: FormEvent){
               
                event.preventDefault()
                if (state === appState.submitting){
                    console.log("Cannot submit again yet, already submitting!")
                    return 
                } 
                setState(appState.submitting)
        
                let err: Record<string, string> = Validateform(values.name, values.email, values.message)
                
                setErrors(err)
                console.log(err)
                let validForm = !Object.keys(err).some(function(k) {return err[k]})
                
                console.log(validForm)
                if (validForm) {sendEmail(event)} else {setState(appState.filling)}
        
            }
        
            
            function onChange(e: {target: HTMLInputElement} | {target: HTMLTextAreaElement}){
                setErrors({nameErr: '', emailErr: '', msgErr: ''})
        
                const {name, value} = e.target
            
                setvalues({
                    ...values,
                    [name]: value
                })

           
        
            }

            function sendEmail(e: FormEvent) {
                console.log("sendEmail called...")
                e.preventDefault();
                
                let target = e.target as HTMLFormElement
                
                emailjs.sendForm('service_j2rl3lc', 'template_rlkz5js', target, 'user_05iEPBImW1xsH5aBR3kEb')
                  .then((result): void => {
                    console.log(result.text)         
                    // alert("Success!")
                    // setState(appState.submitted)
                    const delayedCall = setTimeout(() => {
                        setState(appState.submitted)
                        clearTimeout(delayedCall)
                    }, 1000)
          
                  }, (error) => {
                      console.log(error.text)
                      setErrors({'api': 'Connection error. Please try again.' })
                      alert("An error occured. Please try again.")
                  });   
            }
        }


let SuccessScreen = () => {
    const TOP_DECAL_STYLE = css`
    height: 1.9%;
    width: 82.7%;
    margin: 0 auto;
    background: rgb(77, 72, 72);
    `
    const LOGO_STYLE = css`
        width: fit-content;
        margin: 21.6px auto;
        color: black;
        font-size: 9vw;
        padding: 5.5px 5px ;
    `

    const TITLE_STYLE = css`
        color: black;
        text-align: center;
        font-size: 45px;
        -webkit-text-stroke: 1px #000000;
        line-height: 1.22;
        margin: 9.6px 43.5px 50px 44.5px;
    `

    const msg1 = css`
        color: #201f1f;
        font-size: 18px;
        font-family: 'Poppins';
        margin: 24.8px 49.4px 23.4px 39.6px;
        font-weight: 500;
    `

    const msg2 = css`
        font-size: 15px;
        line-height: 1.8;
        font-family: 'Poppins';

    `

    const GIF_STYLE = css`
        height: 66px;
        width: 66px;
    
        background-image: url(${mgif});
        background-size: cover;
        margin: 0 auto;

    `

    const symb = css`
        color: green;
        margin-top: 35px;
    `

    const GLOBALS = css`
        color: black;
        * {
            text-align: center;
            
        }

        h2, p {
            color: black;
            padding: 0 25.5px;
            
        }
    `
    
    return (
        <>
            <Global styles={GLOBALS}/>
            <h1 css={TITLE_STYLE}>Thank you!</h1>
            {/* <h1 css={symb}>✔</h1> */}
            <div css={GIF_STYLE}></div>
            
            <p css={msg1}>Your Message Was Sent.</p>
            <p css={msg2}>
                A confirmation email was sent to your 
                email address , you will also be notified of 
                Kingston furniture's updates and offers.
            </p>
        </>
    )
}

    if (window.innerWidth < 1000){
        return startPage()
    } else {
        return PromoDesktop()
    }

}


