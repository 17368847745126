import {css} from '@emotion/react'
import { Theme } from '@material-ui/core'


const HomeStyles = (theme: Theme) => ({
    CHIN: css`
        background-color: #4d5c6b;
        color: white;
   
    `,
    BANNER: css`
       
        background-color: #e2a32d;
        text-transform: uppercase;
        text-shadow: 5rem;
        font-weight: bold;
        color: red;
        font-family: 'Poppins';
`,

    SocialMedia: css`
        position: absolute;
        left: 3rem;
        top: 50%;
        transform: translateY(-50%);

    `,

    COPYRIGHT: css`
      

        ${theme.breakpoints.up('sm')}{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        
        }
   `,
    QuickLinks: css`
        height: fit-content;
        padding: 0 3rem;
        display: flex;
        justify-content: center;

    `,
    BODY: css`
        padding: 4rem 0px 3rem;
        overflow-x: hidden;
    `,
    CONTAINER: css`
        width: 100%;  
        background-color: #f5f5f5;
        position: relative;        
    `,

    FOOTER: css`
        padding: 1.5rem 0rem;
        background-color: #1e2a37;
        text-align: center;
        position: relative;
      
    `
})

export default HomeStyles