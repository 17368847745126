import React, { FormEvent, useState } from 'react'
import { css, Global} from '@emotion/react'
import imgSrc from 'resources/images/bedroom/bedroom_image-min.jpg'
import Validateform from './Validateform'
import emailjs from 'emailjs-com';
import mgif from 'resources/images/success_one_fast.gif';
import { ClipLoader} from 'react-spinners'


const PromoDesktop = () => {

    enum appState {
        submitted = 'submitted',
        filling = 'filling',
        submitting = 'submitting',
        unsubmitted = 'unsubmitted'
    }

    const [values, setvalues] = useState({name: '', email: '', message: ''})
    const [errors, setErrors] = useState<Record<string, string>>({nameErr: '', emailErr: '', msgErr: '', api: ''})
    const [state, setState] = useState<appState>(appState.unsubmitted)
    let hasErrors = !Object.keys(errors).some(function(k) {return errors[k]})
    
    const CONTAINER_STYLE = css`
    width: 100%;
    height: 100%;
    position: relative;
    `
    
    const FOOTER_STYLE = css`
        position: fixed;
        bottom: 0;
        left: 0;
        height: 5.1%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        font-size: 11px;
        font-size: 1vw;
        color: #f5f5f5;
        font: outline;
        background: rgb(68, 67, 67);
        font-family: var(--secondary-font);`

    const IMG_STYLE = css`
        width: 100%;
        height: 100%;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    `
    const TOP_DECAL_STYLE = css`
        margin: 0 auto;
        height: 1.5%;
        width: 74%;
        align-self: center;
        background: rgb(77, 72, 72);
       
        `

    const FORM_STYLE = css`
        width: 100%;
        height: 100%;
        background: white;
        display: block;
        
        @media only screen and (min-width: 1000px){
            position: relative;
            height: 100%;
            width: 34.4%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;       
        }`

    const LOGO_STYLE = css`
        width: fit-content;
        display: block;
        margin: 4.9% auto;
        color: black;
        font-size: 4vw;
        /* padding: 5.5px 50px ; */
        border: solid 1px black;
    `

    const TEXT_BOX_STYLE = css`
        height: 16.5%;
        padding-top: 5px;
        `

    const TITLE_STYLE = css`
    width: fit-content;
    margin: 6.8% auto 9.8%;
    color: black;
    /* font-size: 47px; */
    font-size: 3vw;  
    /* font-size: 70px;   */
    white-space: nowrap;
    text-align: center;
    `

    const BTN_STYLE = css`
        height: 5%;
        width: 45.7%;
        display: block;
        margin: 9.5% auto;
        border: none;
        background-color: #c93736;
        font-weight: 100;
        font-size: 12px;
        border-radius: 3px;
        color: #bfffff;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        position: relative;
    `

    const loader_STYLE = css`
        height: 32.1px;
        width: 45.7%;
        text-align: center;  
        margin: 33px auto;
        background-color: #c93736;   
        padding-top: 5px;
        padding-bottom: 35px;
    `


    const GLOBAL_STYLE = css`
        label {
            width: fit-content;
            width: 75.3%;
            margin: 0 auto;
            display: block;
            color: rgb(0, 0, 32);
            font-family: var(--secondary-font);
            }
        
        input, textarea {
            display: block;
            padding-left: 5px;
            
            height: 4.6%;
            width: 75.3%;
            align-self: center;
            
            margin: 8px auto 3.5%;

            ${hasErrors ? css`margin-bottom: 8px;`: css`margin-bottom: 0px;`};
            border: 1px solid lightgray;   
            background: #fff;       
            color: black;
            outline: none;
            font-family: var(--secondary-font);
        
        } 

        input:focus, textarea:focus {
            border: 1px solid black;
        }
        
        p {
            font-size: 12px;
            color: red;
            width: 75.3%;
            margin: 0px auto;
        }`

        
    const normalForm = <>
    <div css={TITLE_STYLE}>Under Construction</div>           
    <label>Your Full Name:</label>
    <input onChange={onChange} placeholder="full name" name='name' type='text'></input>
    {errors.nameErr && <p className='err'>{errors.nameErr}</p>}

    <label>Your Email Address:</label>
    <input onChange={onChange} placeholder="email address" name='email' type='email'></input>
    {errors.emailErr && <p className='err'>{errors.emailErr}</p>}

    <label>Your Message:</label>
    <textarea onChange={onChange} name='message' css={TEXT_BOX_STYLE}></textarea>
    {errors.msgErr && <p className='err'>{errors.msgErr}</p>}
    {/* {state as appState == appState.submitted && <p></p>} */}
    {state === appState.submitting ? <div css={loader_STYLE}><ClipLoader color='#bfffff' size={28}/></div> : <button formAction='submit' css={BTN_STYLE}>SEND MESSAGE</button>}
    
    {errors.api && <span className="serverErr">{errors.api}</span>}
    </>;


    return (
        <React.Fragment>
            <div css={CONTAINER_STYLE}>
                <img src={imgSrc} alt='background' css={IMG_STYLE}/>
                <Global styles={GLOBAL_STYLE}></Global>
                    <form css={FORM_STYLE} action="submit" onSubmit={onSubmit}>
                        <div css={TOP_DECAL_STYLE}></div>                             
                        <div css={LOGO_STYLE}>KF</div>
                        {state !== appState.submitted ? normalForm : SuccessScreen()}
                  
                        <div css={FOOTER_STYLE}>COPYRIGHT © 2021 KINGSTON FURNITURE LTD. ALL RIGHTS RESERVED.</div>
                    </form>    
            </div>
        </React.Fragment>     
    )
    // 

    function onSubmit(event: FormEvent){
               
        event.preventDefault()
        if (state === appState.submitting){
            console.log("Cannot submit again yet, already submitting!")
            return 
        } 
        setState(appState.submitting)

        let err: Record<string, string> = Validateform(values.name, values.email, values.message)
        
        setErrors(err)
        console.log(err)
        let validForm = !Object.keys(err).some(function(k) {return err[k]})
        
        console.log(validForm)
        if (validForm){sendEmail(event)} else {setState(appState.filling)}

    }

    
    function onChange(e: {target: HTMLInputElement} | {target: HTMLTextAreaElement}){
        setErrors({nameErr: '', emailErr: '', msgErr: ''})

        const {name, value} = e.target
    
        setvalues({
            ...values,
            [name]: value
        })


    }

    function sendEmail(e: FormEvent) {
        console.log("sendEmail called...")
        e.preventDefault();
        
        const target = e.target as HTMLFormElement
        
        emailjs.sendForm('service_j2rl3lc', 'template_rlkz5js', target, 'user_05iEPBImW1xsH5aBR3kEb')
          .then((result): void => {
            setState(appState.submitted)
    
            
          }, (error) => {
              console.log(error.text)
              setErrors({'api': 'Connection error. Please try again.' })
              alert("An error occured. Please try again.")
          });   
    }


    
}

const SuccessScreen = () => {

    const TITLE_STYLE = css`
        color: black;
        text-align: center;
        font-size: 45px;
        -webkit-text-stroke: 1px #000000;
        line-height: 1.22;
        margin: 9.6px 43.5px 50px 44.5px;
        color: black;
        padding: 0 25.5px;      
        text-align: center; 
    `

    const msg1 = css`
        color: #201f1f;
        font-size: 18px;
        font-family: 'Poppins';
        margin: 24.8px auto 23.4px;
        font-weight: 500;
        color: black;
        padding: 0 25.5px;      
        text-align: center;
    `

    const msg2 = css`
        font-size: 15px;
        line-height: 1.8;
        font-family: 'Poppins';
        color: black;
        padding: 0 25.5px;      
        text-align: center; 
    `

    const GIF_STYLE = css`
        height: 66px;
        width: 66px;
    
        background-image: url(${mgif});
        background-size: cover;
        margin: 0 auto;

    `



    const GLOBALS = css`
        color: black;
        /* * {
            text-align: center;          
        } */

        /* h1, p, div {
            color: black;
            padding: 0 25.5px;      
            text-align: center;      
        } */
    `
    
    return (
        <>
            <Global styles={GLOBALS}/>
            <h1 css={TITLE_STYLE}>Thank you!</h1>

            <div css={GIF_STYLE}></div>
            
            <p css={msg1}>Your Message Was Sent.</p>
            <p css={msg2}>
                A confirmation email was sent to your 
                email address. You will also be notified of 
                Kingston Furniture's future updates and offers.
            </p>
        </>
    )
}

export default PromoDesktop
