import {css} from '@emotion/react'
import nav_im from 'resources/images/bedroom/bedroom_image-min.jpg'
import mobile_im from 'resources/images/bedroom/bedroom_image-min_crop.jpg'
import {Theme, createMuiTheme, makeStyles} from '@material-ui/core/styles'


// const theme = createMuiTheme()

export const useStyles = makeStyles({
    backdrop: {backgroundColor: 'blue', height: '300px'}
});

const CENTERED_EL = css`
    width: fit-content;
    height: fit-content;
    display: block;
    margin: 0 auto;
`
// export const  = {
//     : css`
        
//     `,

//     : css`
        
//     `
// }

export const SliderDots = (theme: Theme) => ({
    CONTAINER: css`
        margin: calc(60% - 2rem) auto;
        /* position: absolute;
        top: 60%; */

        ${theme.breakpoints.up('sm')}{
            display: none;
    }
    `

    // : css`
        
    // `
})

export const ImageSliderChin = (theme: Theme) => ({
    CONTAINER: css`
        padding-top: 1rem;
        padding-bottom: 1rem;
        
    `,

    MENU_ITEM: css`  
        justify-content: center; 
    `,

    ROW: css`
        margin-top: 1.2rem;
    `,

    BTN: css`
        width: 66.7%;
    `

})


export const CategoryCard = (theme: Theme) => ({

    CONTENT: css`     
        padding: 0.5rem 0rem 0rem;
    `,
        
    TEXT: css`     
        font-family: 'Playfair';
        /* font-size: 34px; */
    `,

    FOOTER: css`
        height: 6px;
        width: 56.5%;
        background-color: #1e2a37;
        
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%)
    `, 
    BTN: css`
        margin: 0 auto;
        color: black;
        border-color: black;
        border-radius: 0%;

        ${theme.breakpoints.up('sm')}{
            width: 11rem;
            height: 2rem;
            margin: 0.8rem auto 0;      
        }

    `, 
    
    OUTLINE: css`     
        width: 92%;
        height: 90.1%;
        margin: 0.4rem auto 0;     
        background-color: transparent;
        border: solid white;

        ${theme.breakpoints.up('sm')}{
            margin: 0.8rem auto 0;      
        }
    `,

    CONTAINER: css`
        height: 16rem;
        position: relative;


        ${theme.breakpoints.up('sm')}{
            height: 27rem;        
        }

    `,
    IMG : css`
        height: 60.9%;
        width: 90.8%;
        margin: 0.5rem auto 0;   
        text-align: center;
        overflow: hidden;
        background-size: contain;
        ${theme.breakpoints.up('sm')}{
            margin: 1rem auto 0px;      
        }
    `,

    // : css`
        
    // `
})

export const CategoryGrid = {
    BOX: css`
        color: white;
        text-decoration: none;
        margin: 0.2rem 0.2rem;
        &:hover {
            opacity: 0.8;
        
        }
    `,
    GRID : css`
        /* column-gap: 0.5rem;
        row-gap: 1rem; */
        
       
    `,

    INNER_GRID : css`
        row-gap: 0.1rem;
        
       
    `,

    // : css`
        
    // `
}

export const SocialMedia = (color: string) => ({
    TEXT: css`   
    /* text-transform: uppercase; */
            margin-top: -0.3rem;   
        `,
    CONTAINER: css`
        
       
    `,

    ICON_STYLE: css`
        color: ${color};
        /* margin: 0 0.5rem; */
    `,

    ICON_GAP: css`
        margin-left: 0.5rem;
    `
})

export const ImageSlider = (theme: Theme) => ({
    DRAWER_ITEM: css`
        width: 15.625rem;
        color: black;
  `,
    ANIMATE: css`
  
    `,
    BANNER: css`
       z-index: 1;     
       width: 50%;
       margin: 0 auto;
       background-color: #e2a32d;
       text-transform: uppercase;
       text-shadow: 5rem;
       font-weight: bold;
       color: red;
       font-family: 'Poppins';
`,

    CONTAINER: css`
            width: 100%;
            height: 100%;

            ${theme.breakpoints.down('sm')}{
                display: none;
            }
        `,

    SHOP_BUTTON: css`
        width: 16.6%;
        position: absolute;
        left: 50%;
        top: 88%;
        transform: translateX(-50%);
        color: white;
        border-color: white;  
`,

    BODY_TEXT: css`
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translateX(-50%);
    text-align: center;
    text-justify: auto;
 
`,
    TITLE_LG: css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        text-justify: auto;
        white-space: nowrap;

        /* ${theme.breakpoints.down('sm')}{

           
        } */
    `,

    LINK_CONTAINER: css `
        position: absolute;
        right: 5%;
        top: 37px;

        ${theme.breakpoints.down('sm')}{

            display: none;
}
    `,

    OTHER_LINKS: css`
        grid-gap: 0px;
    `,

    MENU: css`
        ${theme.breakpoints.up('md')}{
                display: none;
            }
    `,
    FLEX: css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 2rem;

        ${theme.breakpoints.up('md')}{
            justify-content: center;
            margin-top: 3rem;
        }
        
  
    `,
    
    IMAGE: css`
        height: 60%;
        color: white;
        position: relative;

        ${theme.breakpoints.up('sm')}{
            height: 100%;
        }

        &:before  {
            content: "";      
            background-image: url(${mobile_im});
            background-size: cover;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: -1;
            filter: brightness(50%);

            ${theme.breakpoints.up('lg')}{

                background-image: url(${nav_im});
            }
        }
        
    `,
    
    NavLinks: css `
        /* display: grid; */

       
        
 
    `,

    menuGrid: css`
        display: flex;
        align-content: center;
        color: white;
    `,

    MenuItem: css`
        text-transform: uppercase;
        margin: 1rem 1.6rem;
        
    `,

    TOOLBAR: css`
        position: absolute; 
        top: 20%;
        left: 50%;
        transform: translateX(-50%)
    `,

    title: css`

        border: solid white;
        padding: 0.2rem;

        ${theme.breakpoints.up('sm')}{
            /* left: 50%;
            transform: translateX(-50%);
            top: 9%; */
        }
    `,

    SM: css`
        position: absolute;
        top: 37px;
        left: 40px;
    `
})

export const ShoppingSelector = (theme: Theme) => ({
    CONTAINER: css`
        height: 100%;
        width: 100%;
        margin: 0px auto 35px; 
        /* border: 1px blue solid */
        /* border: solid red   */
    `,
    GRID_ITEM: css`
        z-index: 0;
        text-decoration: none;
        /* margin: 0.4rem; */
    


    `,

    GRID: css`
        margin-top: 20px;
        margin-bottom: ${theme.spacing(12)}px;
        
    `,

    TITLE: css`
        color: black;
        /* text-transform: capitalize; */
        /* padding-top: 10px;   */
    `,

    DIVIDER: css`
        width: 22.9%;
        height: 0.25rem;
        margin: 1rem auto 2rem;
        background-color: black;

        ${theme.breakpoints.up('sm')}{
            width: 8.8%;
        }
    `,

    BACKDROP: css`
        width: 85%;
        height: 32rem;   
        
  
        padding: 221px 21px 27.9px 137.5px;
        opacity: 0.83;
        background-color: #2c3e50;
        position: absolute;
        top: ${theme.spacing(25)}px;
        right: 0;

        ${theme.breakpoints.up('sm')}{
            width: 93.2%;
            height: 27rem;   
        }
       
    `
})

export const ArrivalsCard = (theme: Theme) => ({
    CONTAINER: css`
        height: 249px;
        position: relative;

        ${theme.breakpoints.up('lg')} {
            height: 648px;
        }
    `,

    IMG: css`
        width: 100%;
        height: 100%;   
    `,

    TEXT1: css`

        position: absolute;
        bottom: 18%;
       
        left: 3.4%;
        font-family: 'Poppins';
      
        font-weight: 300;
        line-height: 1.5;  
        color: white;
        white-space: nowrap;
    `, 

    TEXT2: css`
        width: 167px;
        height: 32px;
        position: absolute;
        bottom: 15%;
        left: 3.4%;
        line-height: 2.29; 
        text-align: left;
        color: #ffffff;
        white-space: nowrap;


    `
})

export const DiscountsCard = (theme: Theme) => ({

    CONTAINER: css`   
        height: 10rem;
        background-color: #2c3e50;
        position: relative;
        padding: 1.5rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        ${theme.breakpoints.up('lg')} {
            height: 397px;
            padding: 2.5rem 0;
        }
    `,

    TEXT1: css`
        text-transform: uppercase;
    `, 

    TEXT2: css`
        margin: 3rem auto;
        /* text-transform: capitalize; */
    `, 



    DIVIDER: css`
        width: 42%;
        height: 2%;
        background-color: white;
        margin: 0.5rem auto;
    `,
    

    BTN: css`
        margin-top: 1rem;
        color: white;
        text-decoration: none;
        text-transform: capitalize;
        border-radius: 0;
        ${theme.breakpoints.up('lg')} {
     
    }       
    `
})

export const FeaturedCard = (theme: Theme) => ({

    CONTAINER: css`
        height: 10rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
         
        ${theme.breakpoints.up('lg')} {     
            height: 243px;
        }
    `,

    IMG_STYLE: css `
        width: 100%;
        height: 100%;
        background-size: cover;
        /* filter: brightness(90%);
        opacity: 0.9; */
        z-index: 0;
        position: absolute;
    `,

    TEXT1: css`
        margin-bottom: 2rem;
        text-transform: uppercase;
        z-index: 1;
        
        
        /* align-self: flex-start */
        /* position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%); */

    `,

    TEXT2: css`
        width: 42.1%;
        height: fit-content;
        font-family: 'Poppins';
     
        font-weight: 300;
        line-height: 1.5;  
        text-align: center;
        justify-content: center;
        color: white;
        white-space: nowrap;
        border: solid white;
        border-radius: 0;
    

        /* position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -65%); */
     
    `
})

export const QuickLinks = (theme: Theme) => ({
    MENU_ITEM: css`
        color: white;
    `,
    ITEM: css`
        text-transform: uppercase;
    `,
    CONTAINER: css`
        width: 60%;
        height: 100%;  
        float: left;
        padding-top: 2rem;
        padding-bottom: 20px;
     
    `
   
})

export const MailSection = (theme: Theme) => ({
    SUBSCRIBE: css`
        width: 3rem;
    `,
    ITEM: css`
        margin: 0 0 1.5rem;    
        justify-content: center;

        ${theme.breakpoints.up('lg')}{
            justify-content: flex-end;     
        }
       
    `,
    CONTAINER: css`
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        

        ${theme.breakpoints.up('sm')}{
            width: 40%;
            height: 100%;
        
        }
     
       
        
    `,

    TITLE: css`
        ${CENTERED_EL};
        
    
    `,

    INPUT: css`
        height: 2.3rem; 
        width: 94.4%;
        text-align: center;  
        color:black;

        ${theme.breakpoints.up('sm')}{
            width: 12rem;
        
        }
 
    
    `,

    BTN: css`
        height: 2.3rem;
        width: 12rem;
        background-color: #b440b5;
        color: #bfffff;
        border-radius: 0;
    `,

    SMALL: css`
        ${CENTERED_EL};
        font-family: 'Poppins';
        font-size: 20px;
        margin-top: 18px;     
        position: relative;     
    `   
})



export const Testimonials = (theme: Theme) => ({

    
    ITEM: css`
        height: 30rem;
    `,
    
    CONTAINER2: css`   
       padding: 2rem 0 0.5rem;

        ${theme.breakpoints.up('sm')}{
            padding: 5rem 0 1rem; 
        }
    `,

    DIVIDER: css`
        height: 2.5px;
        width: 8.8%;
        background-color: black;
        position: relative;
       
        margin: 1rem auto 2rem;
    `,

    CONTAINER: css`   
        /* height: 50rem; */
        color: black;
        background-color: white;
        text-align: center;    
        padding: 15px;


        ${theme.breakpoints.up('lg')} {
            height: fit-content;
         
        }
    `,

    GRID: css`
        column-gap: 2rem;
    `,

    TITLE: css`
        ${CENTERED_EL};
        color:#000000;
    `,


    STARS: css`
        display: block;
        font-weight: bold;
        font-size: 26px;
        letter-spacing: 1.5px;
    `,

    NAME: css`
        display: block;
        font-weight: bold;
        font-size: 16px;
    `,

    COMMENT: css`
        height: 6rem;
        color: black;
        font-family: var(--secondary-font);
        font-style: italic;
        font-size: 12px;
        padding: 5px;
    `,

    SLIDER: css`
        height: min-content; 
        color: black; 
        font-size: 50px;  
        text-align: center;
        position: relative;
        bottom: 30px;

    `

})

export const ContactForm = (theme: Theme) => ({
    nameField: css`
        width: 100%;
        height: 56px;
        inline-size: 100%;
        /* margin: 0 25px 21px 0; */
        padding: 17px 0px 16px 16.5px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.12);   
    `
, emailField: css`
        width: 100%;
        max-width: 100%;
        height: 56px;
        inline-size: 100%;
     
        /* size: 100; */
        /* margin: 0 0 21px 25px; */
        /* background-color: red;; */
        padding: 17px 0px 16px 16.5px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.12);
        /* &::placeholder {
        overflow: visible; */
      }

    `
    , textarea: css`
        resize: none;
        width: 100%;
        height: 133px;
        /* margin: 21px 0 48px; */
        padding: 19.4px 0px 90.6px 16.5px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.12);
    `
    , container: css`
        width: 100%;
        height: 400px;
        margin: 34px auto 98px;
        padding: 53px 44px 40px 39px;
        background-color: #fff;
  /* row-gap: 5px;
  column-gap: 1px; */
    `

    , btn: css`
    width: 226px;
  height: 49px;
  /* margin: 48px auto 0; */
  padding: 11px 60px 15px;
  background-color: #1b7a7a;
    `
    , test1: css`
    border: solid 6px blue;
    /* background-color: red; */
    `
    , test2: css`
    border: solid 1px red;
    background-color: red;
    `

   
})