

export default function Validateform(name: string, email: string, msg: string) {
    let errors = {nameErr: '', emailErr: '', msgErr: ''}

    name = name.trim()
    email = email.trim()
    msg = msg.trim()
    
    if (name.length > 85 || !name){
        errors.nameErr = "Please provide a name."
    }

    if (email.length > 50 || !email){
        console.log(email, !email)
        errors.emailErr = "Please provide a valid email."
    }

    if (msg.length > 500 || !msg){
        errors.msgErr = "Please provide a message."
    }

    return errors

}
