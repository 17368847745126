import React, { useState } from 'react';
import Promo from 'pages/Promo/promo'
import HomePage from 'pages/home/HomePage'
import Checkout from 'pages/checkout/Checkout'
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import './App.css'
import * as Themes from 'resources/fonts/MyFonts'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Box, Slide } from '@material-ui/core';
import ProductsPage from 'pages/Products/ProductsPage'
import Cart, {cartProps, triggerFunc} from 'pages/cart/ShoppingCart'
import {productProps} from './pages/Products/Components/Product/ProductManager'
import {AppBarPrimary, AppbarSecondary} from 'Appbars/Appsbars'



function App() {
  const [cartOpen, setCartOpen] = useState(false)
  const [cartList, setCartList] = useState<productProps[]>([])

  const toggleDrawer: triggerFunc = (isOpen: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setCartOpen(isOpen)
  };

  const cartHandler: cartProps = {
    cartLst: cartList,
    editCartLst: setCartList,
    cartIsOpen: cartOpen,
    toggleOpenCart: toggleDrawer,  
  }

  
  return (
      <StylesProvider injectFirst>
        <ThemeProvider theme={Themes.PRIMARY_THEME}>
       
          <Router>
            <Route render={({location}) => {
              const {pathname, key} = location
              const PrimaryAppBarPages = ['/']

              return (           
                <Slide appear={(() => pathname != '/')()} direction='left' in key={key} timeout={600} >
                  <Box height='100%' width='100%'>
                    {/* {PrimaryAppBarPages.includes(pathname) ? <AppBarPrimary trigger={toggleDrawer}/>  : <AppbarSecondary/> } */}
                     <Cart {...cartHandler}/>             
                    <Switch location={location}>
                      <Route exact path='/' component={HomePage}/>
                      <Route exact path='/contact' component={Promo}/>
                      {/* <Route exact path='/products' component={ProductsPage}/> */}
                      <ThemeProvider theme={Themes.CHECKOUT_THEME}>
                        <Route exact path='/checkout' component={Checkout}/>
                      </ThemeProvider>                
                    </Switch> 
                  </Box> 
                </Slide>
              )}}/> 

          </Router>
        </ThemeProvider>    
      </StylesProvider>
  );
}


export default App;
