import React, { FormEvent, useState } from 'react'
import './Components.css'
import * as R from 'resources/Utils'
import im from 'resources/images/bedroom/bedroom_image-min_crop.jpg'


import * as Styles from './ComponentStyles'
import {HashRouter, Link} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {Grid, AppBar, Toolbar, Menu, MenuItem,  Typography, Button, Divider, Paper, Card, CardMedia, Container, Box, CardContent, CardActionArea, CardActions, Slide, createMuiTheme, SwipeableDrawer, List, ListItem, ListItemText} from '@material-ui/core'
import {FaTwitter, FaFacebookF, FaInstagram, FaBars} from 'react-icons/fa'
import { MdKitchen, MdOutlineLocationOn, MdOutlineEmail } from "react-icons/md"
import { FiPhoneCall} from "react-icons/fi"

import emailjs from 'emailjs-com';
import * as Themes from 'resources/fonts/MyFonts'

import arrivals_preview from 'resources/images/new_arrivals_preview.jpg'
import featured_im from 'resources/images/featured_products_bckgrnd.jpg'
import {PRIMARY_THEME} from 'resources/fonts/MyFonts'
import { css } from '@emotion/react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
// require.context('../assets', false, /\.(png|jpe?g|svg)$/)





const theme = createMuiTheme()

 
// export const Drawer = ({open}: Record<string, boolean>) => {
//     const toggle = () => {}

//     const content = <List>
//         {R.NavItemList.slice(2).map(obj => {
//             // <ListItem button>{obj.title}</ListItem>
//         })}
//     </List>

//     return (
//         <SwipeableDrawer open={open} onOpen={toggle} onClose={toggle}>
//             {content}
//         </SwipeableDrawer>
//     )

// }

export const SocialMedia = ({text, color} : Record<string, string>) => {

    const S = Styles.SocialMedia(color)
    return (
        <Grid container alignItems='center' justify='center'>
            <Grid item><FaTwitter css={S.ICON_STYLE}/></Grid>
            <Grid item><FaFacebookF css={[S.ICON_STYLE, S.ICON_GAP]}/></Grid>
            <Grid item> <FaInstagram css={[S.ICON_STYLE, S.ICON_GAP]}/></Grid>
            {text && <Grid item><Typography align='center' variant='body2' color='inherit' css={[S.TEXT, S.ICON_GAP]}>{text}</Typography></Grid>}     
        </Grid>
    )
}

export let ImageSlider = ({images}: Record<string, Array<string>>) => {

    const S = Styles.ImageSlider(theme)
  
    const isMobile = R.Queries().isBelowSM

    const [banner, setBanner] = useState(false)
    const [openDrawer, setopenDrawer] = useState(false)
    

    setTimeout(() => {
        setBanner(true)
    }, 3000);

    let ImgChanger = () => {
        // TODOs
      
    }

    const toggle = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (openDrawer === isOpen === true) {return}
        setopenDrawer(isOpen)
    }

    const Drawer = () => {

        const content = (
            <React.Fragment>
                <List css={S.DRAWER_ITEM}>
                    {R.NavItemList.slice(0, 3).map((obj) => (
                        <ListItem button key={obj.title} component={Link} to='/contact'>
                            <ListItemText primary={obj.title} ></ListItemText>
                        </ListItem>          
                    ))}
                    <Divider/>
                    <ListItem button key='Dining' component={Link} to='/contact'>
                            <ListItemText primary={'Dining'}></ListItemText>
                    </ListItem>      
                    {R.NavItemList.slice(4, 7).map((obj) => (
                        <ListItem button key={obj.title} component={Link} to='/contact'>
                            <ListItemText primary={obj.title}></ListItemText>
                        </ListItem>          
                    ))}
                    <Divider/>
                    {R.NavItemList.slice(7).map((obj) => (
                        <ListItem button key={obj.title} component={Link} to='/contact'>
                            <ListItemText primary={obj.title}></ListItemText>
                        </ListItem>          
                    ))}

                </List>
            

            </React.Fragment>
        )
        

    return (
        <div>
        <SwipeableDrawer anchor='left' open={openDrawer} onOpen={toggle(true)} onClose={toggle(false)}>
            {content}
        </SwipeableDrawer>
        </div>
    )}


    const NavLinks = () => {
        return (  
            <ul css={S.menuGrid}>
                {R.NavItemList.slice(R.NavItemList.length - 3).map(item => {
                    return (              
                        <MenuItem disableGutters to={item.url} smooth component={HashLink} css={S.MenuItem}>
                            {item.title}
                        </MenuItem>     
                    )
                })}    
            </ul> 

       )
    }

    return (
    <React.Fragment>
        <AppBar css={S.IMAGE} position='relative'>
            {/* <Drawer/> */}
            {/* {banner && !isMobile && 
            <Slide in direction='down' timeout={800} css={S.ANIMATE}> 
                        <Typography variant='body1' align='center' css={S.BANNER}>website under construction</Typography> 
            </Slide>} */}
            <Box css={S.FLEX}>
                <FaBars size={40} onClick={toggle(true)} css={S.MENU}/>
                <Typography color='primary' variant='h3' css={S.title}>KF</Typography>
            </Box>
            <SliderDots/>
           

            <div css={S.CONTAINER}>               
                {/* <div css={S.SM}><SocialMedia text='Stay connected' color='#bfffff'/></div> */}
                {/* <span css={S.LINK_CONTAINER}>
                    <Grid container css={S.OTHER_LINKS}>
                        <MenuItem to='/contact' component={Link}>
                            <Typography color='textPrimary' variant='body1'>Cart</Typography>
                        </MenuItem>

                        <MenuItem to='/contact' component={Link}>
                            <Typography color='textPrimary' variant='body1'>Search</Typography>
                        </MenuItem>

                        <MenuItem to='/contact' component={Link}>
                            <Typography color='textPrimary' variant='body1'>Login</Typography>
                        </MenuItem>
                    </Grid>
                </span>
             */}
                <Toolbar css={S.TOOLBAR}>
                    <NavLinks/>
                </Toolbar>
                <Typography color='textPrimary' variant='h1' css={S.TITLE_LG}>ENRICH YOUR<br/> SPACE</Typography>

                <Typography color='textPrimary' variant='subtitle1' align='center' css={S.BODY_TEXT}>{R.HEADER_BODY}</Typography>

                <Button component={HashLink} to='#showcase' variant='outlined' size='large'  css={S.SHOP_BUTTON}>{R.START_SHOPPING}</Button>
    
            </div>
        </AppBar>
    </React.Fragment>
)}


export let ImageSliderChin = ({images}: Record<string, Array<string>>) => {
    let S = Styles.ImageSliderChin(theme)

    return (
    <React.Fragment>
        <Container css={S.CONTAINER}>
            <Typography variant='h4' align='center' >{R.HEADER_TITLE}</Typography>
            <Typography align='center' css={S.ROW}>{R.HEADER_BODY}</Typography>
            <MenuItem  alignItems='center'  component={Link} to='/contact' css={[S.MENU_ITEM, S.ROW]}>
                <Button variant='outlined' size='large' color='inherit' css={S.BTN}>{R.EXPLORE_NOW}</Button>
            </MenuItem>    
        </Container>
    </React.Fragment>
)}


export let ShoppingSelector = ({categories, images}: Record<string, Array<string>>) => {
    const activeCards = []
    type Filter = R.Filter
    const [activeFilters, setActiveFilters] = useState<Filter[]>(['vanities'])

    const toggleFilter = (category: Filter) => {
        setActiveFilters([category])
    
        // const filteredProducts = cardLst.filter((val) => (activeFilters.includes(val.category)))

    }
    
    let createCards = () => {

        // imgs.map()
        // console.log(imgs)

        let cardLst: Array<JSX.Element> = []
        let slicerLst: Array<JSX.Element> = []
        // let iconLst = [livingroom, kitchen, bedroom]  
      

        // const filteredProducts = productDetails.filter((val) => (activeFilters.includes(val.category)))

        // categories.forEach((category, index) => {
        //     let obj = CategoryCard(images[index], category)
        //     const slicer = <Slicer text={category}/>
        //     cardLst.push(obj)       
        // });

        R.productLst.forEach((product) => {
            if (true) {
                const card = CategoryCard({img_src:product.img, category:product.category})
                // console.log(product.category, activeFilters.includes(product.category))
                // const slicer = <Slicer category={product.category} isActive={activeFilters.includes(product.category) ? true: false}/>
                cardLst.push(card)       
            } 
        
        });

        return cardLst;
    }


    // .forEach(item => (console.log(item)))
    // for (const [key, value] of Object.entries(R.CATEGORIES)) {
    //     console.log(key, value);
    //   }


    let cardLst = createCards()

    const S = Styles.ShoppingSelector(theme)
    const classes = Styles.useStyles()

    return (
    <Box id='showcase' mt='60px'>
        <Typography variant='h4' align='center' css={S.TITLE}>{R.SECT_2_TITLE}</Typography>
        <Divider variant='middle'  css={S.DIVIDER}/>
        {/* <Paper square css={S.BACKDROP}/> */}

        <Grid container spacing={1} css={S.CONTAINER} direction='row' justify='center' alignItems='center'>
           {Object.entries(R.CATEGORIES).map(([key, val]) => {
               return (
                <Grid item>
                <Slicer 
                        category={key}
                        label={val.label} 
                        icon={val.icon} 
                        isActive={activeFilters.includes(key as Filter) ? true: false}
                        func={toggleFilter}
                    />
                </Grid>
            )})} 
        </Grid>

              
        <Grid container alignItems='center' justify='center' spacing={2} css={S.GRID}>      
            {R.productLst.map((product) => {
                    const isVisble = activeFilters.includes(product.category) 
                    return (
                    isVisble && <Grid item lg={4} xs={5} component={HashLink} to='#contact' css={S.GRID_ITEM}>
                        <CategoryCard img_src={product.img} category={product.category}/>
                    </Grid>
                    )
                })} 
            {/* {cardLst.map((card) => {
                console.log(card.props)
                return (
                <Grid item lg={4} xs={5} component={Link} to='/contact' css={S.GRID_ITEM}>
                    {card}
                </Grid>
                )
            })} */}
        </Grid>

        <CategoryGrid/>
    </Box>
)}





export let MenuBtn = () => {
    return (
    <React.Fragment>
        <ul id='bar-cont'>
            <li  className='barRows'><div id='bar1' className='appbar'></div></li>
            <li  className='barRows'><div id='bar2' className='appbar'></div></li>
            <li  className='barRows'><div id='bar3' className='appbar'></div></li>
            <li  className='barRows'><div id='menu'>Menu</div></li>
        </ul>   
    </React.Fragment>
)}


export let SliderDots = () => {
    const S = Styles.SliderDots(theme)
    return (
    <React.Fragment>
            <div css={S.CONTAINER}>
                <span id='dot1' className='dot'>.</span>
                <span id='dot2' className='dot' style={{color: '#b440b5'}}>.</span>
                <span id='dot3' className='dot'>.</span>
                <span id='dot4' className='dot'>.</span>
            </div>  
    </React.Fragment>
)}

export const Logo = () => {return (<React.Fragment><h3 className='logo'>KF</h3></React.Fragment>)}


export const Slicer = ({category, label, color, icon, isActive, func} : Record<string, any>) => {

    const styles = {
        container: css`
            width: 232px;
            background-color: ${isActive ? '#1b7a7a': 'none'};
            /* margin: auto 2px; */
            /* border: solid red; */

            /* ${theme.breakpoints.up('sm')}{
                margin: 1rem auto 0px;      
            } */
        `
    }
    const S = Styles.SocialMedia(color)
    return (
        <Grid container item  alignItems='center' 
            justify='center' 
            id={category} 
            component='button' 
            onClick={() => func(category)}
            css={styles.container}
            >  
            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center' textAlign='center' p='18px 22px 18px 12px' color='#312929'> 
                {icon ? <Grid item>{icon}</Grid> : ''}
                {category && <Grid item><Typography align='center' variant='button' color='inherit' css={[S.TEXT, S.ICON_GAP]}>{label}</Typography></Grid>}
            </Box>             
        </Grid>
    )
}

export const CategoryCard = ({img_src, catergory}: Record<string, any>) => {
    const isMobile = R.Queries().isBelowSM
    const S = Styles.CategoryCard(theme)

    return (
        <Card>
            <CardActionArea>
                <Container disableGutters css={S.CONTAINER}>
                    <CardMedia image={img_src} about={'Home and office Furniture for the ' + catergory} css={S.IMG}>
                        {/* <Box css={S.OUTLINE}/> */}
                    </CardMedia>
                    
                    <CardContent css={S.CONTENT}>
                        <Typography variant='h6' align='center' color='textSecondary' css={S.TEXT}>{catergory}</Typography>
                    </CardContent>
                    
                    <CardActions>
                        <Button component={HashLink} to='#contact' variant='outlined' size={isMobile ? 'medium': 'large'} css={S.BTN}>{R.EXPLORE_NOW}</Button>
                    </CardActions>
                    <footer css={S.FOOTER}/>
                </Container>         
            </CardActionArea>       
        </Card>
)}


export let ArrivalsCard = ({imgSrc}: Record<string, string>) => {
    const S = Styles.ArrivalsCard(theme)
    
    return (
        <Card css={S.CONTAINER} square>
            <CardMedia image={imgSrc} css={S.IMG}/>
            <Typography variant='subtitle1' css={S.TEXT1}>{R.A_CARD_TXT1}</Typography>
            <Typography variant='h3' css={S.TEXT2}>{R.A_CARD_TXT2}</Typography>
        </Card>
)}


export const ContactCard = ({imgSrc, discnt}: Record<string, string>) => {
    const isMobile = R.Queries().isBelowSM
    const S = Styles.DiscountsCard(theme)

    return (
    <React.Fragment>
        <Card css={S.CONTAINER} >
                 {imgSrc && <img className='A-card-img' src={imgSrc} alt='discounts preview'/>}
                
                {/* <Typography align='center' variant='h3' color='textPrimary' css={S.TEXT1}>Sale {discnt}%</Typography> */}
                <Typography align='center' variant='h3' color='textPrimary' css={S.TEXT1}>let's talk</Typography>

                <div css={S.DIVIDER}/>
                { !isMobile && <Typography align='center' variant='body1'  css={S.TEXT2}>
                Contact us to schedule a consultation and customize your order today.           
                    {/* {capitalize('contact us for a consultation and customize your order today')}  */}
                </Typography>}
           
                <Button component={HashLink} to='#contact' variant='outlined' color='inherit'>CONTACT</Button>
         
                
        </Card>
    </React.Fragment>
)}

export let FeaturedCard = ({imgSrc, discnt}: Record<string, string>) => {

    const S = Styles.FeaturedCard(theme)

    return (
    <React.Fragment>
            <div css={S.CONTAINER}>
                {imgSrc && <img css={S.IMG_STYLE} src={imgSrc} alt='discounts preview'/>}
                <Typography variant='subtitle1' css={S.TEXT1}>Our projects</Typography>
                <Button size='large' css={S.TEXT2}>LEARN MORE</Button>     
            </div>              
    </React.Fragment>
)}




export const CategoryGrid = () => {
    const S = Styles.CategoryGrid

    return (
        <Grid id='featured' container justify='center' css={S.GRID}>

            <Grid item xs={10} lg={6} component={HashLink} smooth to='#contact' css={S.BOX}>
                <ArrivalsCard imgSrc={arrivals_preview}/>
            </Grid>
          
            <Grid container item lg={4} justify='center' css={S.INNER_GRID}>
                <Grid item xs={5} lg={12} component={HashLink} smooth to='#contact' css={S.BOX}>               
                    <ContactCard discnt={'50'}/>
                </Grid>

                <Grid item xs={5} lg={12} component={HashLink} smooth to='#contact' css={S.BOX}>               
                    <FeaturedCard imgSrc={featured_im}/>
                </Grid>          
            </Grid>                   
        </Grid>
    )
}

export const Testimonials = () => {
    const S = Styles.Testimonials(theme)

   
    const isMobile = R.Queries().isBelowMD

    const Testimony = ({name, comment}: Record<string, string>) => {
        return (
            <Container css={S.CONTAINER}>
                <small css={S.STARS}>*****</small>
                <small css={S.NAME}>{name}</small>
                <Typography css={S.COMMENT}> 
                    {comment}
                </Typography>             
            </Container>
        )
    }


    return (
        <Container css={S.CONTAINER2}>      
            <Typography variant='h3' css={S.TITLE}>Testimonials</Typography>
            <div css={S.DIVIDER}/>
            <Grid container alignItems='center' justify='center' css={S.GRID}>
            <Grid item xs={6} lg={3}><Testimony name='NO REVIEWS' comment={"Subscribe to our mailing list and you can be the first to leave a review once the website is fully launched!"}/></Grid>
                {/* {!isMobile && <Grid item lg={3} ><Testimony name='Tessa John' comment={"I am very satisfied with the service. My order was completed quickly and the furniture is of good quality."}/></Grid>}
                {!isMobile && <Grid item lg={3}><Testimony name='Tajari Walker' comment={"I wish I could find this kind of furniture when I first bought my home. The best furniture for reasonable prices."}/></Grid>} */}
                {/* <Grid item xs={6} lg={3}><Testimony name='Shanice Narina' comment={"This company is awesome!! The best furniture and hardware I have ever bought and excellent customer service!"}/></Grid>
                {!isMobile && <Grid item lg={3} ><Testimony name='Tessa John' comment={"I am very satisfied with the service. My order was completed quickly and the furniture is of good quality."}/></Grid>}
                {!isMobile && <Grid item lg={3}><Testimony name='Tajari Walker' comment={"I wish I could find this kind of furniture when I first bought my home. The best furniture for reasonable prices."}/></Grid>} */}
                {/* {isMobile && <SliderDots/>} */}
            </Grid>
    
            

        </Container>
    )
}


export let MailSection = () => {
    const S = Styles.MailSection(Themes.PRIMARY_THEME)
    const sizeCheck = R.Queries()

    
    return (
        <Grid container item justify='center' alignContent='center'  css={S.CONTAINER} lg={5}>
            {sizeCheck.isBelowXS && <Grid item lg={12}>
                <Typography align='center' variant='subtitle2' color='inherit'>Stay Connected</Typography>
            </Grid>}

            <Grid item xs={12} css={S.ITEM}>
                <Typography  variant='h5' align='center' color='inherit'>Join Our Mailing List</Typography>
            </Grid>

            <Grid container item lg={6} css={S.ITEM} justify='center'>    
                <input placeholder='Your email address' id='email' type='email' name='email' css={S.INPUT}></input>

            </Grid>

            <Grid item lg={6} css={S.ITEM}>
                <Button color='primary' size='large' variant='outlined' css={S.BTN}> SUBSCRIBE</Button>
            </Grid>

            {sizeCheck.isBelowXS && <Grid item xs={12}>
                <SocialMedia text='Follow Us'/>
            </Grid>}
        </Grid>

    )
}

export const QuickLinks = () => {
    const S = Styles.QuickLinks(Themes.PRIMARY_THEME)

    return (
        <Grid container item alignItems='center' direction='row-reverse' css={S.CONTAINER}>
            {R.Lists.quickLinks.map(link => (
            <Grid item lg={3}>
                <MenuItem css={S.MENU_ITEM} component={Link} to='/contact'>
                    <Typography variant='body2' align='center' color='inherit' css={S.ITEM}>{link.title}</Typography>
                </MenuItem>
                
            </Grid>) 
            )}
        </Grid>
    )
}

export const UpperFooter = () => {

    const sizeCheck = R.Queries()
    return (
    <Box bgcolor={PRIMARY_THEME.palette.secondary.main} 
        height= 'fit-content'
        p='0 3rem'
        display='flex'
        justify-content='center'
    >
    {!sizeCheck.isBelowSM && <QuickLinks/>}
    <MailSection />
    </Box>)
}

export const Footer = () => {
    const styles = {
            copyright: css`
                width: fit-content;
                height: auto;
                /* inline-size: fit-content; */
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                
      
                text-align: center;
                /* border: solid blue; */
                /* margin-right: 0; */
                /* margin: 0 auto; */
                /* display: flex; */
                /* align-self: center; */
                /* display: inline; */
            `
        ,   top: css`
                width: fit-content;
                /* inline-size: fit-content; */
                height: 100%;
                margin-right: 24px;
                margin-left: auto;
                /* text-decoration: none; */
             
                text-transform: uppercase;
    `
        }


    const sizeCheck = R.Queries()

    return (

        <Box py='0.5rem' width='100%' bgcolor='#1e2a37' alignItems='center' justifyContent='space-between' display='flex' position='relative'>
            <Typography color='primary' css={styles.copyright}> 
                        COPYRIGHT © 2021 KINGSTON FURNITURE LTD. {sizeCheck.isBelowSM && <br/>} ALL RIGHTS RESERVED.
            </Typography>
            <Typography component={HashLink} to='#' color='primary' css={styles.top}> 
                back to top
            </Typography>
      
                {/* {!sizeCheck.isBelowSM && <Box display='inline-block' width='10%' color='#f5f5f5'><SocialMedia text='Follow Us' color='#bfffff'/></Box>} */}
                {/* <Box width='90%' display='inline-block' position='relative' left='-5%'  textAlign='center' color='#f5f5f5'>        
                    <Typography color='inherit'> 
                        COPYRIGHT © 2021 KINGSTON FURNITURE LTD. {sizeCheck.isBelowSM && <br/>} ALL RIGHTS RESERVED.
                    </Typography>
                </Box> */}
        </Box>
    // <footer css={S.FOOTER}>
    // <Box css={S.SocialMedia}>
    //     {!sizeCheck.isBelowSM && <comps.SocialMedia text='Follow Us' color='#bfffff'/>}
    // </Box>
    
    // <Typography color='textPrimary' align='center' css={S.COPYRIGHT}>
    //     COPYRIGHT © 2021 KINGSTON FURNITURE LTD. {!sizeCheck.isBelowSM && <br/>} ALL RIGHTS RESERVED.
    // </Typography>
    
    // </footer>
    )
}



export const ContactForm = () => {

    enum appState {
        submitted = 'submitted',
        filling = 'filling',
        submitting = 'submitting',
        unsubmitted = 'unsubmitted'
    }

    const [values, setvalues] = useState({name: '', email: '', message: ''})
    const [errors, setErrors] = useState<Record<string, string>>({nameErr: '', emailErr: '', msgErr: '', api: ''})
    const [state, setState] = useState<appState>(appState.unsubmitted)
    const hasErrors = !Object.keys(errors).some(function(k) {return errors[k]})

    function sendEmail(e: FormEvent) {
        console.log("sendEmail called...")
        e.preventDefault();


        const target = e.target as HTMLFormElement
        emailjs.sendForm(process.env.REACT_APP_emailServiceID as string
            , process.env.REACT_APP_emailTemplate as string
            , target
            , process.env.REACT_APP_emailUsr as string)
          .then((result): void => {
            setState(appState.submitted)
            alert("Message sent successfully. Check your email for confirmation. Check your junk if the email does not appear.")
            console.log('SUccss!')
    
            
          }, (error) => {
              console.log(error.text)
              setErrors({'api': 'Connection error. Please try again.' })
              alert("An error occured. Please try again or use another method to contact us.")
              setState(appState.submitted)
          });   
    }



    function onSubmit(event: FormEvent){
             
        console.log('submitted!')
        event.preventDefault()
        if (state === appState.submitting){
            console.log("Cannot submit again yet, already submitting!")
            return 
        } 
        setState(appState.submitting)
        sendEmail(event)

    }

    
    const styles = {
            heading: css`
                width: auto;
                height: 66px;
                margin: 125px auto 0 ;
                font-family: Forum;
                font-size: 53px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.09;
                letter-spacing: 4.77px;
                color: #312929; 
                text-transform: uppercase;
            `
        ,   subhead: css`
            width: auto;
            height: 20px;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: normal;
            text-align: center;
            color: #312929;
            text-transform: uppercase;
        `
        ,   contacts: css`
            width: 100%;
            height: auto;
            margin: 54px auto;
        `
        ,   tag: css`
                height: 96px;
                background-color: white;
                /* text-transform: capitalize; */
                font-family: 'roboto';
         `
        ,   contacttxt: css`  
                font-family: 'poppins';
                margin-top: 5px;
                align-self: center;
                vertical-align: middle;
`
        }

        
    const ContactTag = ({label, icon}: Record<string, string | ReactJSXElement>) => {

        return ( 
                <Box width='100%' css={styles.tag} p='12px' textAlign='center'> 
                    {icon}
                    <Typography css={styles.contacttxt}>{label}</Typography>
                </Box>  
        )
    }

    const contactMethods = [' 876-678-3097', 'admin@kingston-furniture.com', '6 East Ashenheim Rd Kingston 11. Jamaica. W.I']
    const methodIcons = [<FiPhoneCall/>, <MdOutlineEmail/>, <MdOutlineLocationOn/>]
    const S = Styles.ContactForm(Themes.PRIMARY_THEME)
    
  return (
    <Box width='55.9%' m='0 auto' color='black'  textAlign={'center'} id='contact'> 

    {/* <Typography css={styles.heading}>
    Lorem ipsum dolor
    </Typography> */}

    <Typography css={styles.heading}>
        Contact us 
    </Typography>

    {/* <Typography css={styles.subhead}>
    Lorem ipsum dolor sit amet, consectetur adipiscing
    </Typography> */}

    <Typography css={styles.subhead}>
        Use our contact information below or send a message.
    </Typography>

    <Grid container direction='row' spacing={1}  justify='center' alignItems='center' css={styles.contacts}>
        {contactMethods.map((method, i) => (
            <Grid item sm={4}><ContactTag label={method} icon={methodIcons[i]}/></Grid>          
        ))}
    </Grid>


      <form onSubmit={onSubmit} css={S.container} >
        <Grid container spacing={3} justify='center' css={css`width: 100%;`}> 
            <Grid item xs={12} sm={6}>
            <input
                required
                id="name"
                name="name"      
                placeholder="NAME"
                autoComplete="given-name" 
                maxLength={30}
                // size={40}    
                width='100%'
                css={S.nameField}
                // sx={{ input: { color: 'red' } }}
            />
            </Grid>
            <Grid item xs={6} sm={6} >
                <input
                    required
                    type='email'
                    id="email"
                    name="email"  
                    placeholder="EMAIL"  
                    autoComplete="email"           
                    maxLength={50}  
                    css={S.emailField}
                />
            </Grid>
            <Grid item xs={12}>
                    <textarea      
                    required
                    id="message"
                    name="message"
                    placeholder="YOUR MESSAGE"
                    // fullWidth
                    autoComplete="shipping address-line1"
                    // multiline
                    rows={10}
                    maxLength={200}
                    css={S.textarea}
                    // variant='outlined'
                />
            
            </Grid>

            <Grid container item xs={12} sm={12} justify='center'>
                <Button type='submit' variant='text' css={S.btn}>SEND</Button>
            </Grid>
        </Grid>
      </form>
      
    </Box>
  );
}








