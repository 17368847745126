import { Box, Card, CardContent, Typography } from '@material-ui/core'
import { CardMedia } from '@material-ui/core'
import { Drawer } from '@material-ui/core'
import React, { useState } from 'react'
import {productProps} from 'pages/Products/Components/Product/ProductManager'

export type triggerFunc = (cartIsOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void


export interface cartProps {
  cartIsOpen: boolean,
  cartLst: productProps[],
  toggleOpenCart: triggerFunc,
  editCartLst: (cartLst: productProps[], action: 'remove' | 'add', payload?: productProps[]) => void
}



const ShoppingCart = (props: cartProps) => {

    const CartItem = (product:productProps) => (
        <Box>
            <Card>
                <CardMedia image={product.imagePath}></CardMedia>
                <CardContent>
                    <Typography>{product.price}</Typography>
                </CardContent>
            </Card>
        </Box>
    )

    console.log(props.cartLst.length)

    return (
        <div>
            {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
            <Drawer anchor={'right'} open={props.cartIsOpen} onClose={props.toggleOpenCart(false)}>
              {props.cartLst.length < 1 ? <Typography color={'textSecondary'}>There are no items in your cart</Typography> : 
                props.cartLst.map(product => {
                    <CartItem {...product}/>
                })}
            </Drawer>
      </div>
    )
}


export default ShoppingCart


